<template>
  <div>
    <div
      class="notification-dialog"
      :class="{ 'show-notifications': show, 'hide-notifications': !show }"
    >
      <notification v-show="!showSettings" @open-settings="handleOpen" />
      <notification-settings
        v-show="showSettings"
        @hide-settings="handleHide"
      />
    </div>
  </div>
</template>

<script>
import Notification from "./Notification";
import NotificationSettings from "./NotificationSettings";

export default {
  name: "NotificationDesktop",

  components: {
    Notification,
    NotificationSettings
  },

  props: {
    show: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      showSettings: false
    };
  },

  watch: {
    show(newVal) {
      if (!newVal) {
        this.showSettings = false;
      }
    }
  },

  methods: {
    handleOpen() {
      this.showSettings = true;
    },
    handleHide() {
      this.showSettings = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-dialog {
  opacity: 0;
  position: fixed;
  width: 360px;
  height: calc(100vh - 28px);
  background-color: $white;
  padding: 1.5rem;
  bottom: 15px;
  left: -450px;
  z-index: 1000;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.notification-dialog.show-notifications {
  opacity: 1;
  left: 71px;
  transition: all 0.4s ease-in-out;
}

.notification-dialog.hide-notifications {
  opacity: 0;
  left: -450px;
  transition: all 0.4s ease-in-out;
}
</style>
